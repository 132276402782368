import type { MembershipModalState } from './types';

export const mutations = {
	setAmount: (state: MembershipModalState, amount: number): MembershipModalState => ({
		...state,
		amount,
	}),
	setGeneralErrorMessage: (state: MembershipModalState, generalErrorMessage: string): MembershipModalState => ({
		...state,
		...{ formState: { status: 'error', error: generalErrorMessage } },
	}),
	clearGeneralErrorMessage: (state: MembershipModalState): MembershipModalState => ({
		...state,
		...{ formState: null },
	}),
	setLoading: (state: MembershipModalState): MembershipModalState => ({
		...state,
		...{ formState: { status: 'loading' } },
	}),
	clearLoading: (state: MembershipModalState): MembershipModalState => ({
		...state,
		...{ formState: null },
	}),
	setAcceptMembership: (state: MembershipModalState, acceptMembership: boolean): MembershipModalState => ({
		...state,
		acceptMembership,
	}),
};
