import React from 'react';

import { Translate, TranslateHtml } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconSecurity } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { PolicyInfoProps } from './hooks/usePolicyInfo';
import { usePolicyInfo } from './hooks/usePolicyInfo';

export function PolicyInfo(props: PolicyInfoProps): React.JSX.Element {
	const { gateway, gatewayLink, privacyPolicyLink, termsOfServiceLink } = usePolicyInfo(props);

	return (
		<Flex sx={{ alignItems: 'center', flexDirection: 'row' }}>
			<Icon icon={iconSecurity} color="secondary-blue" size="xxl" />
			<Text as="span" size="caption" sx={{ color: 'neutral-grey600', marginLeft: 16 }}>
				<TranslateHtml
					value="corgi.payments.policy_info.text"
					replacements={{
						gateway,
						gatewayLink: (
							<Link to={gatewayLink} sx={{ color: 'neutral-grey600', fontWeight: 'normal' }} target="_blank">
								<Translate value="corgi.payments.policy_info.their_terms" />
							</Link>
						),
						privacyPolicyLink: (
							<Link to={privacyPolicyLink} sx={{ color: 'neutral-grey600', fontWeight: 'normal' }} target="_blank">
								<Translate value="corgi.payments.policy_info.privacy_policy" />
							</Link>
						),
						termsOfServiceLink: (
							<Link to={termsOfServiceLink} sx={{ color: 'neutral-grey600', fontWeight: 'normal' }} target="_blank">
								<Translate value="corgi.payments.policy_info.terms_of_service" />
							</Link>
						),
					}}
				/>
			</Text>
		</Flex>
	);
}
