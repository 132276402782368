import { useEffect } from 'react';

import type { PaymentRequest } from '@stripe/stripe-js';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentType } from 'src/app/shared/utils/payments';

import { useOnPaymentMethod } from './hooks/useOnPaymentMethod';

export function usePaymentRequestOnPaymentMethod(
	paymentRequest: PaymentRequest | null,
	paymentType: PaymentType,
	withToken: (params: WithTokenParams) => Promise<void>,
): void {
	const onPaymentMethod = useOnPaymentMethod(paymentType, withToken);

	useEffect(() => {
		if (!paymentRequest) return undefined;
		paymentRequest.on('paymentmethod', onPaymentMethod);
		return () => {
			paymentRequest.off('paymentmethod');
		};
	}, [onPaymentMethod, paymentRequest]);
}
