import { useCallback, useEffect, useRef } from 'react';

export function useLoadTimeTracking({
	trackLoadEndTime,
	trackLoadStartTime,
	flow,
}: {
	trackLoadStartTime: () => void;
	trackLoadEndTime: (duration: number) => void;
	flow: paypal.FlowType;
}): () => void {
	const loadingStartTime = useRef<number>(0);
	useEffect(() => {
		loadingStartTime.current = performance.now();
		trackLoadStartTime();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flow]);

	return useCallback(() => {
		trackLoadEndTime(Math.ceil(performance.now() - loadingStartTime.current));
	}, [trackLoadEndTime]);
}
