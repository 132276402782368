import { createStateContext } from '@change-corgi/core/react/context';

import type { ModalOptions } from '../../shared/types';

import { initialState } from './initialState';
import { mutations } from './mutations';
import type { MembershipModalState } from './types';

export const {
	StateContext: MembershipModalStateContext,
	StateProvider: MembershipModalStateContextProvider,
	useStateContext: useMembershipModalState,
} = createStateContext<MembershipModalState, typeof mutations, ModalOptions>({
	name: 'MembershipModalStateContext',
	mutations,
	initialState: (state) => initialState(state),
});
