import { useCallback } from 'react';

import type { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentType } from 'src/app/shared/utils/payments';

export function useOnPaymentMethod(
	paymentType: PaymentType,
	withToken: (params: WithTokenParams) => Promise<void>,
): (e: PaymentRequestPaymentMethodEvent) => Promise<void> {
	return useCallback(
		async (event) => {
			await withToken({ paymentType, token: event.paymentMethod.id, onTransactionComplete: event.complete });
		},
		[paymentType, withToken],
	);
}
