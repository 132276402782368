import { useMemo } from 'react';
import type { JSX, PropsWithChildren } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import stripeConfig from 'config/stripe.json';

import { useCountryCode } from 'src/app/shared/hooks/l10n';

type StripeTokenizationAccountIdConfig = Record<
	string,
	{ tokenization_account: { id: string | undefined }; betas?: string[] }
>;

export function StripeLoader({ children }: PropsWithChildren<EmptyProps>): JSX.Element {
	const utilityContext = useUtilityContext();
	const countryCode = useCountryCode();

	const createPaymentMethodConfig: StripeTokenizationAccountIdConfig = stripeConfig.create_payment_method_config;

	const tokenizationId = createPaymentMethodConfig[countryCode]?.tokenization_account.id;
	const betas = createPaymentMethodConfig[countryCode]?.betas;

	const environment = utilityContext.environment.getEnvironment();
	const stripePromise = useMemo(
		async () =>
			loadStripe(stripeConfig.key[environment], {
				stripeAccount: tokenizationId,
				betas,
			}),
		[betas, environment, tokenizationId],
	);

	return <Elements stripe={stripePromise}>{children}</Elements>;
}
