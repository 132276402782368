import { useMembershipModalState } from '../../modalContext';

export function useShowPaypalButton(): boolean {
	const [{ gateway, usingSavedPaymentMethod, acceptMembership, tokenizationData }] = useMembershipModalState();
	return (
		gateway === 'BRAINTREE' &&
		!usingSavedPaymentMethod &&
		tokenizationData?.tokenType !== 'permanent' &&
		acceptMembership
	);
}
