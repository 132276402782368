import type { PaypalState } from 'src/app/shared/hooks/payments';
import { isError } from 'src/app/shared/utils/async';

import type { PaymentType } from '../index';

type PaypalSdkErrorArgs = {
	paypalState: PaypalState;
	availableNewPaymentMethods: PaymentType[];
};

export function isPaypalSdkLoadError({ paypalState, availableNewPaymentMethods }: PaypalSdkErrorArgs): boolean {
	return availableNewPaymentMethods.some((paymentType) => paymentType === 'paypal' && isError(paypalState));
}
