import type { PaymentType } from 'src/app/shared/utils/payments';

import type { SavedPaymentMethod } from '../../types';

type Result = ModelHookResult<{
	savedPaymentMethodsData?: SavedPaymentMethod[] | undefined;
	selectedPaymentType: PaymentType;
	showNewPaymentMethodButtons: boolean;
	showNewStripePaymentMethod: boolean;
	showNewPaypalPaymentMethod: boolean;
	showNewStripePaymentRequestButton: boolean;
	showNewSepaPaymentMethod: boolean;
	showSavedPaymentMethodButtons: boolean;
	showNewUpiPaymentMethod: boolean;
}>;

export function usePaymentMethodSelector({
	savedPaymentMethods,
	selectedPaymentType,
	usingSavedPaymentMethod,
	availableNewPaymentMethods,
}: {
	savedPaymentMethods: SavedPaymentMethod[] | undefined;
	selectedPaymentType: PaymentType;
	usingSavedPaymentMethod: boolean;
	availableNewPaymentMethods: PaymentType[];
}): Result {
	return {
		data: {
			selectedPaymentType,
			showNewPaymentMethodButtons: !usingSavedPaymentMethod && availableNewPaymentMethods.length > 1,
			showNewStripePaymentMethod: !usingSavedPaymentMethod && selectedPaymentType === 'creditCard',
			showNewPaypalPaymentMethod: !usingSavedPaymentMethod && selectedPaymentType === 'paypal',
			showNewStripePaymentRequestButton:
				!usingSavedPaymentMethod && (selectedPaymentType === 'applePay' || selectedPaymentType === 'googlePay'),
			showNewSepaPaymentMethod: !usingSavedPaymentMethod && selectedPaymentType === 'sepa',
			showSavedPaymentMethodButtons: !!savedPaymentMethods?.length,
			showNewUpiPaymentMethod: !usingSavedPaymentMethod && selectedPaymentType === 'upi',
		},
	};
}
