import camelCase from 'lodash/fp/camelCase';
import flow from 'lodash/fp/flow';
import upperFirst from 'lodash/fp/upperFirst';
// Mapping of Stripe client API error types to a format consistent with Stripe Node SDK
// Only necessary to explicitly map types that can't be mapped using a 'Stripe' + pascal case pattern
const STRIPE_ERROR_TYPE_MAP: Record<string, string> = {
	api_connection_error: 'StripeConnectionError',
	api_error: 'StripeAPIError',
};

export function stripeErrorType(clientLibErrorType: string): string {
	const errorType =
		STRIPE_ERROR_TYPE_MAP[clientLibErrorType] || `Stripe${flow(camelCase, upperFirst)(clientLibErrorType)}`;
	return errorType;
}
