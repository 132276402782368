import type { Environment } from '@change-corgi/core/environment';
import { getWindow } from '@change-corgi/core/window';

// TODO move to fe-config
import dLocalConfig from 'config/dlocal.json';

import type { DLocalSDK } from './types';

type CountryToAccountMapKeys = 'ar' | 'default';
type AccountKeys = 'original' | 'argentina';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		dlocal: (token: string) => DLocalSDK;
	}
}

export function init(environment: Environment, countryCode: string): DLocalSDK {
	const { token, countryToAccountMap } = dLocalConfig;
	const account: string =
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		countryToAccountMap[countryCode.toLowerCase() as CountryToAccountMapKeys] || countryToAccountMap.default;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return getWindow().dlocal(token[environment][account as AccountKeys]);
}
