import type { StripeError } from '@stripe/stripe-js';

const STRIPE_ERROR_TYPES = [
	'api_connection_error',
	'api_error',
	'authentication_error',
	'card_error',
	'idempotency_error',
	'invalid_request_error',
	'rate_limit_error',
	'validation_error',
];
export function isStripeError(error: unknown): error is StripeError {
	return (
		typeof error === 'object' &&
		error !== null &&
		'type' in error &&
		typeof error.type === 'string' &&
		STRIPE_ERROR_TYPES.includes(error.type)
	);
}
