import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';
import type { LoginState } from '@change-corgi/middle-end/gql';

type FirstNameValidationArgs = {
	firstName: string;
	loginState: LoginState;
};

export function validateFirstName({ firstName, loginState }: FirstNameValidationArgs): TranslationKey | null {
	// This line here to check for the conditions under which we actually display the first name field to the user,
	// since we can't get it from the user state.
	if (loginState !== 'GUEST') return null;

	if (!firstName.trim().length) return translationKey('fe.errors.credit_card.first_name'); // TODO: move i18n key to corgi namespace

	return null;
}
