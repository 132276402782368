import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';

import type { PaymentType } from '../index';

type NameValidationArgs = {
	name: string;
	selectedPaymentType: PaymentType;
	usingSavedPaymentMethod: boolean;
};

export function validateName({
	name,
	selectedPaymentType,
	usingSavedPaymentMethod,
}: NameValidationArgs): TranslationKey | null {
	// These two lines are here to check for the conditions under which we actually display the name field to the user, e.g. to use as
	// the billing name when they are paying with a new credit card and sepa. We should look into making this a concern of the
	// NewPaymentMethodStripe component or src/app/shared/components/payments/stripe/containers/v2/StripeForm
	if (!['creditCard', 'sepa', 'upi'].includes(selectedPaymentType)) return null;
	if (usingSavedPaymentMethod) return null;

	if (!name.trim().length) return translationKey('fe.errors.credit_card.name'); // TODO: move i18n key to corgi namespace

	return null;
}
