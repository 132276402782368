import type { JSX } from 'react';

import { Icon } from '@change-corgi/design-system/components/icon';
import { iconStar } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

type Props = {
	text: string;
	selected: boolean;
};

export function HighlightText({ text, selected }: Props): JSX.Element {
	return (
		<Box
			sx={{
				textAlign: 'center',
				backgroundColor: selected ? 'secondary-blue' : 'neutral-grey400',
				color: 'primary-white',
				borderTopLeftRadius: 'standard',
				borderTopRightRadius: 'standard',
			}}
		>
			<Flex sx={{ alignItems: 'center', justifyContent: 'center', py: 4 }}>
				<Icon icon={iconStar} size="l" color="secondary-yellow" data-qa="ppmu-modal-highlight-icon" />
				<Text fontWeight="semibold" size="caption" data-qa="ppmu-modal-highlight-text">
					{text}
				</Text>
			</Flex>
		</Box>
	);
}
