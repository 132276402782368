import { useI18n } from '@change-corgi/core/react/i18n';

import { useConfirm } from 'src/app/shared/hooks/modals';

export function useHighAmountConfirmationModal(amount: number, currencyCode: string): () => Promise<boolean> {
	const { translateHtml, translate, localizeCurrency } = useI18n();

	return useConfirm({
		text: [
			// TODO: Move this i8n key to corgi namespace
			translateHtml('fe.pages.sponsors.confirm_high_amount_payment.modal_body', {
				amount: `<strong>${localizeCurrency(amount, currencyCode, { forceFractionDigits: true })}</strong>`,
			}),
		],
		// TODO: Move these i8n keys to corgi namespace
		cancelLabel: translate('fe.pages.sponsors.confirm_high_amount_payment.cancel'),
		confirmLabel: translate('fe.pages.sponsors.confirm_high_amount_payment.continue'),
		buttonsOrientation: 'horizontal-wide',
		confirmDataQa: 'payment-high-amount-button-confirm-yes',
		cancelDataQa: 'payment-high-amount-button-confirm-no',
	});
}
