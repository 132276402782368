import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentType } from 'src/app/shared/utils/payments';

import type { ModalCallbackProps } from '../../shared/types';

import { useBeforeToken } from './useBeforeToken';
import { useOnTokenError } from './useOnTokenError';
import { useOnTokenInvalid } from './useOnTokenInvalid';
import { useWithToken } from './useWithToken';

type PaymentTokenFunctions = {
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	withToken: (params: WithTokenParams) => Promise<void>;
};

export function usePaymentTokenFunctions(modalCallbackProps: ModalCallbackProps): PaymentTokenFunctions {
	const beforeToken = useBeforeToken();
	const onTokenError = useOnTokenError(modalCallbackProps);
	const onTokenInvalid = useOnTokenInvalid();
	const withToken = useWithToken(modalCallbackProps);

	return {
		beforeToken,
		onTokenError,
		onTokenInvalid,
		withToken,
	};
}
