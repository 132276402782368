import React from 'react';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentType } from 'src/app/shared/utils/payments';

import { PaymentRequestButton } from '../../../../stripe/components/PaymentRequestButton';

import { useNewStripePaymentRequestButton } from './hooks/useNewStripePaymentRequestButton';

export type NewStripePaymentRequestButtonProps = {
	currencyCode: string;
	ask: string;
	amount: number;
	selectedPaymentType: PaymentType;
	clearLoading: () => void;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	withToken: (params: WithTokenParams) => Promise<void>;
	validate: (paymentType: PaymentType) => Promise<boolean>;
	prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
};

export function NewStripePaymentRequestButton(props: NewStripePaymentRequestButtonProps): React.JSX.Element | null {
	const {
		data: { testId, paymentType, ...dataProps },
		actions,
	} = useNewStripePaymentRequestButton(props);

	if (!paymentType) return null;

	return <PaymentRequestButton paymentType={paymentType} {...dataProps} {...actions} data-testid={testId} />;
}
