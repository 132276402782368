import { useEffect } from 'react';

import type { PaymentRequest } from '@stripe/stripe-js';

export function usePaymentRequestOnCancel(paymentRequest: PaymentRequest | null, onCancel: () => void): void {
	useEffect(() => {
		if (!paymentRequest) return undefined;
		paymentRequest.on('cancel', onCancel);
		return () => {
			paymentRequest.off('cancel');
		};
	}, [onCancel, paymentRequest]);
}
