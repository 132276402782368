import type { JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

type Props = {
	amount?: string;
	label: string;
	qaSelectorLabel: string;
};

export function Label({ qaSelectorLabel, label, amount }: Props): JSX.Element {
	return (
		<>
			<Box>
				<Heading size="h5" sx={{ fontWeight: 'bold', lineHeight: 1.2 }} data-qa={qaSelectorLabel}>
					{label}
				</Heading>
			</Box>
			{amount && (
				<Box data-testid="amount-box" sx={{ padding: '8px 0px' }}>
					<Heading
						size="h5"
						sx={{ fontWeight: 'bold', lineHeight: 1, color: '#157DB9' }}
						data-qa="ppmu-modal-contribution-text"
					>
						{amount}
					</Heading>
				</Box>
			)}
		</>
	);
}
