import type { PaymentMethodResult, Stripe, StripeElements } from '@stripe/stripe-js';

export async function createStripePaymentMethod(
	stripe: Stripe,
	elements: StripeElements,
	name: string,
	email: string,
): Promise<PaymentMethodResult> {
	const cardElement = elements.getElement('cardNumber');
	if (!cardElement) throw new Error('No card element found');

	return stripe.createPaymentMethod({
		type: 'card',
		card: cardElement,
		billing_details: {
			name,
			email,
		},
	});
}
