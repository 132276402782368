import { localStorage } from '@change-corgi/core/storage';

import type { PendingSignature } from 'src/app/shared/hooks/signature';

type ConsentInput = {
	consentGiven: boolean;
	name: string;
	text: string;
};

// TODO: extract this and src/app/pages/petition/details/hooks/signPetition/localStoredPendingSignature/index.ts to
// a shared hook folder to co-locate typings, etc
export function getConsentInputFromPendingSigInLocalStorage(petitionId: string): ConsentInput | undefined {
	// TODO use usePendingSignature() shared hook
	const pendingSignatureString = localStorage.getItem(`pending-signature-${petitionId}`);

	if (!pendingSignatureString) return undefined;

	try {
		// TODO maybe add some checks after parsins?
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const pendingSignature = JSON.parse(pendingSignatureString) as PendingSignature;

		/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions */
		if (pendingSignature?.formData?.marketing_comms_consent) {
			return {
				consentGiven: pendingSignature.formData.marketing_comms_consent,
				name: pendingSignature.formData.consent_name,
				text: pendingSignature.formData.consent_text,
			};
		}
		/* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/consistent-type-assertions */
	} catch (e) {
		return undefined;
	}

	return undefined;
}
