import type { PaymentType } from 'src/app/shared/utils/payments';

type OnErrorParams = {
	error: Record<string, unknown>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
};

export function onError({ error, onTokenError }: OnErrorParams): void {
	if (error.message === 'Validation error') return;
	if (error.message === 'Disabled') return;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	onTokenError('paypal', new Error((error.message as string) || 'Unknown paypal error'));
}
