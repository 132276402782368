import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { savePaymentMethod } from '../paymentMethod';

export async function savePaypalPaymentMethod(
	token: string,
	email: string,
	usage: 'OFF_SESSION' | 'ON_SESSION',
	utilityContext: UtilityContext,
): Promise<void> {
	await savePaymentMethod({
		input: {
			customer: { email },
			gateway: 'BRAINTREE',
			usage,
			token,
			type: 'PAYPAL',
			gatewayData: null,
		},
		utilityContext,
	});
}
