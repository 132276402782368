import type { PaypalCheckout } from 'src/app/shared/utils/payments';

type CreateOrderParams = {
	amount?: number;
	currency: string;
	disabled: boolean;
	paypalCheckout: PaypalCheckout | null;
};

export async function createOrder({ amount, currency, disabled, paypalCheckout }: CreateOrderParams): Promise<string> {
	if (!paypalCheckout) {
		return Promise.reject(new Error('No paypal checkout instance'));
	}

	if (disabled) return Promise.reject(new Error('Disabled'));

	return paypalCheckout.createPayment({
		amount,
		currency,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		flow: 'checkout' as paypal.FlowType.Checkout,
	});
}
