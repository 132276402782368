import { useCallback, useEffect } from 'react';

import { useMembershipModalState } from '../../../modalContext';

export function useAmountUpdateListener(): void {
	const [, { setAmount }] = useMembershipModalState();

	const amountChangeHandler = useCallback(
		(event: Event) => {
			if (event instanceof CustomEvent) {
				if (!/^\d*$/.test(String(event.detail))) return;
				const value = Number(event.detail);
				if (value < 1) return;
				setAmount(value);
			}
		},
		[setAmount],
	);

	useEffect(() => {
		document.addEventListener('sub-amount-change-event', amountChangeHandler);
		return () => {
			document.removeEventListener('sub-amount-change-event', amountChangeHandler);
		};
	}, [amountChangeHandler]);
}
