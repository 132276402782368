import { type ChangeEventHandler, useEffect } from 'react';
import React from 'react';

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements } from '@stripe/react-stripe-js';
import type {
	StripeCardCvcElementChangeEvent,
	StripeCardExpiryElementChangeEvent,
	StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { Input } from '@change-corgi/design-system/components/forms';
import { Image } from '@change-corgi/design-system/components/media';
import { Box, Flex } from '@change-corgi/design-system/layout';

import { InfoTooltip } from 'src/app/shared/components/tooltips';
import type { StripeValidationErrors } from 'src/app/shared/utils/payments';

type Props = {
	validationErrors?: StripeValidationErrors;
	onChange?: (
		e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
	) => void;
	onCardNumberFieldFocus: () => void;
	onCardExpiryFieldFocus: () => void;
	onCardCVCFieldFocus: () => void;
};

// eslint-disable-next-line max-lines-per-function
export function StripeCreditCardElements({
	validationErrors,
	onChange,
	onCardNumberFieldFocus,
	onCardExpiryFieldFocus,
	onCardCVCFieldFocus,
}: Props): React.JSX.Element {
	const { translate } = useI18n();

	const elements = useElements();

	useEffect(() => {
		if (!elements) return;
		if (validationErrors?.card_number) {
			elements.getElement('cardNumber')?.focus();
		} else if (validationErrors?.card_cvc) {
			elements.getElement('cardCvc')?.focus();
		} else if (validationErrors?.card_expiration) {
			elements.getElement('cardExpiry')?.focus();
		}
	}, [validationErrors, elements]);

	return (
		<>
			<Box
				mb={8}
				data-testid="credit-card-number"
				sx={{
					position: 'relative',
					WebkitTransform: 'translate3d(0, 0, 0)',
				}}
			>
				<Input
					// eslint-disable-next-line react/forbid-component-props
					className="iframe-form-element" // TODO: this is here for the regression-qaa tests. Migrate the tests to use the data-qa attribute.
					data-qa="iframe-form-element"
					error={validationErrors?.card_number ? translate(validationErrors.card_number) : null}
					label={translate('fe.components.credit_card.cc_number')}
					name="card-number"
					as={CardNumberElement}
					onFocus={onCardNumberFieldFocus}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					onChange={onChange as unknown as ChangeEventHandler<HTMLInputElement>}
					id="card-number-input"
					sx={{
						height: 40,
						display: 'flex',
						alignItems: 'center',
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'.StripeElement': {
							width: '100%',
						},
					}}
				/>
				<Box sx={{ position: 'absolute', right: '0', top: '0' }}>
					<Image
						alt=""
						height="20px"
						src="https://assets.change.org/app-assets/victory_fund/credit-card-icons-2.png"
						width="163px"
					/>
				</Box>
			</Box>

			<Flex sx={{ width: '100%' }}>
				<Box sx={{ flex: 1 }} mr={8} data-testid="credit-card-expiry">
					<Input
						// eslint-disable-next-line react/forbid-component-props
						className="iframe-form-element" // TODO: this is here for the regression-qaa tests. Migrate the tests to use the data-qa attribute.
						data-qa="iframe-form-element"
						error={validationErrors?.card_expiration ? translate(validationErrors.card_expiration) : null}
						label={translate('corgi.payments.stripe_elements.expiration')}
						name="card-expiration"
						as={CardExpiryElement}
						onFocus={onCardExpiryFieldFocus}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						onChange={onChange as unknown as ChangeEventHandler<HTMLInputElement>}
						id="card-expiry-input"
						sx={{
							height: 40,
							display: 'flex',
							alignItems: 'center',
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'.StripeElement': {
								width: '100%',
							},
						}}
					/>
				</Box>
				<Box sx={{ flex: 1 }} data-testid="credit-card-cvc">
					<Input
						// eslint-disable-next-line react/forbid-component-props
						className="iframe-form-element" // TODO: this is here for the regression-qaa tests. Migrate the tests to use the data-qa attribute.
						data-qa="iframe-form-element"
						error={validationErrors?.card_cvc ? translate(validationErrors.card_cvc) : null}
						label={
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(
								<Box sx={{ height: 20 }}>
									<Translate value="corgi.payments.stripe_elements.cvc" />{' '}
									<Box sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
										<InfoTooltip data-testid="credit-card-cvc-info" tabIndex={0} id="cvc-tooltip">
											{translate('corgi.payments.stripe_elements.cvc_tooltip')}
										</InfoTooltip>
									</Box>
								</Box>
							) as unknown as string
						}
						name="card-cvc"
						as={CardCvcElement}
						onFocus={onCardCVCFieldFocus}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						onChange={onChange as unknown as ChangeEventHandler<HTMLInputElement>}
						id="card-cvc-input"
						sx={{
							height: 40,
							display: 'flex',
							alignItems: 'center',
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'.StripeElement': {
								width: '100%',
							},
						}}
					/>
				</Box>
			</Flex>
		</>
	);
}
