import { useEffect, useRef } from 'react';
import type { JSX } from 'react';

import { useDLocal } from 'src/app/shared/hooks/payments';
import type { Field, FieldType, SmartFieldEvent } from 'src/app/shared/utils/payments';

type Props = {
	type: FieldType;
	onBlur: (event: SmartFieldEvent) => void;
	onFocus?: (event: SmartFieldEvent) => void;
	onFieldCreated?: (field: Field) => void;
	placeholder?: string;
};

export function DLocalSmartField({ type, onBlur, onFocus, onFieldCreated, placeholder }: Props): JSX.Element {
	const { smartFields, status } = useDLocal();
	const fieldPlaceholder = useRef<HTMLDivElement>(null);
	const fieldRef = useRef<Field>();

	useEffect(() => {
		if (status !== 'ready' || smartFields === null || fieldPlaceholder.current === null || !!fieldRef.current) {
			return;
		}
		const field = smartFields.create(type, {
			style: {
				base: {
					fontSize: 16,
					lineHeight: 24,
					marginLeft: 8,
				},
			},
			placeholder,
			hideIcon: true,
		});
		if (onFieldCreated) {
			onFieldCreated(field);
		}
		field.mount(fieldPlaceholder.current);
		field.on('blur', onBlur);
		if (onFocus) {
			field.on('focus', onFocus);
		}
		fieldRef.current = field;
	}, [status, smartFields, fieldPlaceholder, fieldRef, type, onBlur, onFocus, onFieldCreated, placeholder]);

	return <div ref={fieldPlaceholder}></div>;
}
