import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconInfo } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { AmountButton } from './components/AmountButton';
import { AmountInput } from './components/AmountInput';

type MemoizedAmountButtonProps = {
	amount: number;
	currency: string;
	isSelected: boolean;
	onAmountChange: (value: number, isButton?: boolean) => void;
};

function MemoizedAmountButton({ amount, onAmountChange, ...rest }: MemoizedAmountButtonProps): React.JSX.Element {
	const handleClick = useCallback(() => onAmountChange(amount, true), [amount, onAmountChange]);
	return <AmountButton amount={amount} onClick={handleClick} {...rest} />;
}

type AmountSelectorProps = {
	onAmountChange: (value: number, isButton?: boolean) => void;
	onCustomAmountFocus: () => void;
	buttonAmounts: readonly number[];
	currency: string;
	amountErrorText: string | null;
	scrollToInputOnError: boolean;
	initialValue: number;
};

export function AmountSelector({
	onAmountChange,
	onCustomAmountFocus,
	buttonAmounts,
	currency,
	amountErrorText,
	scrollToInputOnError,
	initialValue,
}: AmountSelectorProps): React.JSX.Element {
	const amountInputWithButtonsRef = useRef<HTMLDivElement>(null);
	const { getCurrencySymbol, getCurrencySymbolPosition, getDecimalSeparator, isZeroDecimalCurrency } = useI18n();
	const isCustomAmount = !buttonAmounts.includes(initialValue);
	const [buttonAmount, setButtonAmount] = useState<number>(isCustomAmount ? 0 : initialValue);
	const [customAmount, setCustomAmount] = useState<number>(isCustomAmount ? initialValue : 0);

	useEffect(() => {
		if (amountInputWithButtonsRef.current && amountErrorText && scrollToInputOnError)
			amountInputWithButtonsRef.current.scrollIntoView();
	}, [amountErrorText, scrollToInputOnError]);

	const handleAmountChange = useCallback(
		(value: number, isButton?: boolean): void => {
			setButtonAmount(isButton ? value : 0);
			setCustomAmount(isButton ? 0 : value);
			onAmountChange(value, isButton);
		},
		[onAmountChange],
	);

	const handleCustomAmountFocus = useCallback(() => {
		setButtonAmount(0);
		if (customAmount === 0) onAmountChange(0);
		onCustomAmountFocus();
	}, [customAmount, onAmountChange, onCustomAmountFocus]);

	return (
		<Box ref={amountInputWithButtonsRef} data-testid="membership-amount-selector">
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(3, 1fr)',
					gap: 8,
					py: 8,
					borderRadius: 4,
				}}
			>
				{buttonAmounts.map((amount) => (
					<MemoizedAmountButton
						key={amount}
						amount={amount}
						currency={currency}
						isSelected={amount === buttonAmount}
						onAmountChange={handleAmountChange}
						data-qa="contribution-amount-button"
					/>
				))}
				<Box
					sx={{
						gridColumn: 'span 2',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<AmountInput
						currencySymbol={getCurrencySymbol(currency)}
						currencySymbolPosition={getCurrencySymbolPosition(currency)}
						decimalSeparator={getDecimalSeparator()}
						errorMessage={amountErrorText}
						onFocus={handleCustomAmountFocus}
						onChange={handleAmountChange}
						showFractionNumber={!isZeroDecimalCurrency(currency)}
						value={customAmount}
						isCustomAmount={isCustomAmount}
					/>
				</Box>
			</Box>
			{amountErrorText && (
				<Flex>
					<Icon icon={iconInfo} size="xl" mr={10} color="status-critical500" />
					<Text
						size="caption"
						fontWeight="semibold"
						data-testid="amount-input-amount-error"
						data-qa="custom-amount-input-error"
						role="alert"
					>
						{amountErrorText}
					</Text>
				</Flex>
			)}
		</Box>
	);
}
