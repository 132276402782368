import type { JSX } from 'react';

import { Expander } from './components/Expander';
import type { ExpanderProps } from './components/Expander';
import { PetitionCard } from './components/PetitionCard';
import type { PetitionCardProps } from './components/PetitionCard';

type AdPreviewProps = PetitionCardProps & ExpanderProps;

export function AdPreview({ petition, ...actions }: AdPreviewProps): JSX.Element {
	return (
		<Expander {...actions}>
			<PetitionCard petition={petition} />
		</Expander>
	);
}
