import { useEffect, useRef } from 'react';

import type { OnInitActions } from '@paypal/paypal-js';

export function useAvailability(
	shouldEnableButton?: boolean,
): (data: Record<string, unknown>, actions: OnInitActions) => void {
	const buttonActions = useRef<OnInitActions>();

	useEffect(() => {
		if (!buttonActions.current || shouldEnableButton === undefined) return;
		async function checkEnabled() {
			if (!shouldEnableButton) {
				await buttonActions.current?.disable();
			} else {
				await buttonActions.current?.enable();
			}
		}
		void checkEnabled();
	}, [shouldEnableButton]);

	return function setAvailability(_data, actions) {
		buttonActions.current = actions;
		if (shouldEnableButton === false) {
			void actions.disable();
		}
	};
}
