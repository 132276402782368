import type React from 'react';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';

import { useStripe } from '@stripe/react-stripe-js';
import type { StripeError } from '@stripe/stripe-js';

import type { TranslationKey } from '@change-corgi/core/i18n';
import { useI18n } from '@change-corgi/core/react/i18n';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentType } from 'src/app/shared/utils/payments';
import { stripeErrorI18nKey } from 'src/app/shared/utils/payments';

import { createStripeUpiPayment } from '../../util/createStripeUpiPayment';

type Params = {
	name: string;
	email: string;
	vpa: string;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	validate: (paymentType: PaymentType) => Promise<boolean>;
	prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	withToken: (params: WithTokenParams) => Promise<void>;
	setVpa: (vpa: string) => void;
	vpaValidationError: string | undefined;
};

type Result = ModelHookResult<
	{
		errorMessage?: string | null;
		validationError?: string | null;
		currentVpa: string;
		vpaLabel: string;
		vpaPlaceholder: string;
	},
	{
		handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
		onVpaChange: (event: ChangeEvent<HTMLInputElement>) => void;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useUpiForm({
	name,
	email,
	beforeToken,
	validate,
	prePaymentChecks,
	onTokenError,
	withToken,
	onTokenInvalid,
	vpa,
	setVpa,
	vpaValidationError,
}: Params): Result {
	const stripe = useStripe();
	const { translate } = useI18n();
	const [errorMessage, setErrorMessage] = useState<TranslationKey | null>();

	const onStripeError = useCallback(
		(error: StripeError) => {
			const message = stripeErrorI18nKey(error);
			setErrorMessage(message);

			onTokenInvalid('upi', new Error(error.message || 'Unhandled Error'));
		},
		[onTokenInvalid],
	);

	const onVpaChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setVpa(e.target.value);
		},
		[setVpa],
	);

	const handleSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			await beforeToken('upi');
			if (!(await validate('upi')) || !(await prePaymentChecks('upi'))) return;
			try {
				if (!stripe) throw new Error('Failed to load Stripe library');
				const createResult = await createStripeUpiPayment(stripe, name, email, vpa);
				if (createResult.error) {
					onStripeError(createResult.error);
					return;
				}
				void withToken({ paymentType: 'upi', token: createResult.paymentMethod.id });
			} catch (error) {
				onTokenError('upi', error as Error);
			}
		},
		[beforeToken, email, name, onStripeError, onTokenError, prePaymentChecks, stripe, validate, vpa, withToken],
	);

	return {
		data: {
			validationError: vpaValidationError,
			errorMessage: errorMessage && translate(errorMessage),
			currentVpa: vpa,
			vpaLabel: translate('fe.components.upi.label'),
			vpaPlaceholder: translate('fe.components.upi.placeholder'),
		},
		actions: {
			handleSubmit,
			onVpaChange,
		},
	};
}
