import type { DLocalSDK, Field } from './index';

type ValidationError = {
	code?: number;
	message?: string;
	param?: string;
};

type CreateTokenError = {
	message?: string;
	error?: ValidationError;
};

type TokenResponse = {
	token?: string | undefined;
	error?: CreateTokenError;
};

export async function getDlocalToken(
	username: string | undefined,
	pan: Field | null,
	sdk: DLocalSDK | null,
): Promise<TokenResponse> {
	if (!sdk || !pan) {
		return {};
	}
	try {
		const { token } = await sdk.createToken(pan, {
			name: username,
		});
		return { token };
	} catch (error) {
		return { error: error as CreateTokenError };
	}
}
