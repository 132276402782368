import type { WithTokenParams } from 'src/app/shared/types';

import { useSubmit } from '../../container/hooks/useSubmit';
import type { ModalCallbackProps } from '../../shared/types';

export function useWithToken(modalCallbackProps: ModalCallbackProps): (params: WithTokenParams) => Promise<void> {
	const submit = useSubmit(modalCallbackProps);
	return async function withToken(params): Promise<void> {
		await submit(params);
	};
}
