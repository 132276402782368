import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { MembershipExistsByEmailQuery, MembershipExistsByEmailQueryVariables } from './api.graphql';

type TestType = {
	email: string | null;
};
export async function membershipExistsByEmail(
	{ email }: TestType,
	{ gql: { fetch } }: UtilityContext,
): Promise<boolean> {
	const { data, errors } = await fetch<MembershipExistsByEmailQuery, MembershipExistsByEmailQueryVariables>({
		query: gql`
			query MembershipExistsByEmail($email: String) {
				membershipExistsByEmail(email: $email) {
					__typename
					hasMembership
				}
			}
		`,
		variables: { email: email ?? null },
	});

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from query');

	return !!data?.membershipExistsByEmail?.hasMembership;
}
