import React from 'react';

import { Button } from '@change-corgi/design-system/components/actions';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';

export type Props = {
	disabled: boolean;
	loading: boolean;
	label: React.JSX.Element;
	onClick?: () => void;
};

export function SubmitButton({ disabled, loading, label, onClick }: Props): React.JSX.Element {
	return (
		<Button
			size="medium"
			// eslint-disable-next-line react/forbid-component-props
			className="js-submit-payment" // TODO: this is here for the regression suite, which should be updated to use a data-qa attribute
			disabled={disabled || loading}
			type="submit"
			variant="primary"
			sx={{ width: '100%' }}
			data-qa="submit-payment-button"
			data-testid="submit-payment-button"
			onClick={onClick}
		>
			{loading ? <Loader data-testid="submit-payment-button-loading" size="s" sx={{ margin: 'auto' }} /> : label}
		</Button>
	);
}
