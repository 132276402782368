import { useCallback, useMemo, useState } from 'react';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import type {
	StripeCardCvcElementChangeEvent,
	StripeCardExpiryElementChangeEvent,
	StripeCardNumberElementChangeEvent,
	StripeError,
	StripeErrorType,
} from '@stripe/stripe-js';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useCountryCode } from 'src/app/shared/hooks/l10n';
import { useCurrentUserCurrency } from 'src/app/shared/hooks/session';
import type { WithTokenParams } from 'src/app/shared/types';
import { isLoaded } from 'src/app/shared/utils/async';
import type { PaymentMethodSaveOptions, PaymentType, StripeValidationErrors } from 'src/app/shared/utils/payments';
import {
	confirmStripeCardSetup,
	createStripePaymentMethod,
	DEFAULT_STRIPE_ERROR_VALIDATION,
	saveStripePaymentMethod,
	setPxDeclineCode,
	setupStripePaymentMethod,
	stripeErrorI18nKey,
	stripeInputName,
	stripeInputValidationError,
} from 'src/app/shared/utils/payments';

import { useLoadStripe } from '../../../../hooks';
import { hasGatewayErrorData } from '../../util/hasGatewayErrorData';

type Params = {
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	email: string;
	name: string;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	validate: (paymentType: PaymentType) => Promise<boolean>;
	withToken: (params: WithTokenParams) => Promise<void>;
	prePaymentChecks?: (paymentType: PaymentType) => Promise<boolean>;
};

type Result = ModelHookResult<
	{
		errorMessage?: string;
		validationErrors: StripeValidationErrors;
	},
	{
		handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
		validateStripeElementOnChange: (
			e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
		) => void;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useStripeForm({
	beforeToken,
	email,
	name,
	paymentMethodSaveOptions,
	onTokenError,
	onTokenInvalid,
	validate,
	withToken,
	prePaymentChecks,
}: Params): Result {
	const elements = useElements();
	const stripe = useStripe();
	const { translate } = useI18n();
	const currentUserCurrencyState = useCurrentUserCurrency();
	const countryCode = useCountryCode();
	const [validationErrors, setValidationErrors] = useState<StripeValidationErrors>(DEFAULT_STRIPE_ERROR_VALIDATION);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

	const utilityContext = useUtilityContext();
	const loadStripeInstance = useLoadStripe();

	const onStripeError = useCallback(
		(error: StripeError) => {
			const inputValidationErrors = stripeInputValidationError(error);

			if (inputValidationErrors) {
				setValidationErrors(inputValidationErrors);
			} else {
				const errorMessageTranslationKey = stripeErrorI18nKey(error);
				if (errorMessageTranslationKey) setErrorMessage(translate(errorMessageTranslationKey));
				else setErrorMessage(error.message);
			}
			setPxDeclineCode(error);

			onTokenInvalid('creditCard', new Error(error.message || 'Unhandled Error'));
		},
		[onTokenInvalid, translate],
	);

	const currency = useMemo(() => {
		if (isLoaded(currentUserCurrencyState)) return currentUserCurrencyState.currency.code;
		return null;
	}, [currentUserCurrencyState]);

	const validateStripeElementOnChange = (
		e: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
	) => {
		setValidationErrors({
			...validationErrors,
			[stripeInputName(e.elementType) as string]: e.error ? stripeErrorI18nKey(e.error) : null,
		});
	};

	const handleSubmitFailure = useCallback(
		(error: unknown) => {
			if (hasGatewayErrorData(error) && error.gatewayErrorData !== null) {
				const stripeError: StripeError = {
					type: error.gatewayErrorData.type as StripeErrorType,
					code: error.gatewayErrorData.code ?? undefined,
					decline_code: error.gatewayErrorData.declineCode ?? undefined,
				};
				onStripeError(stripeError);
			} else {
				onTokenError('creditCard', error as Error);
			}
		},
		[onTokenError, onStripeError],
	);

	const handleSubmit = useCallback(
		// eslint-disable-next-line max-statements, complexity
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			setErrorMessage(undefined);

			await beforeToken('creditCard');

			if (!(await validate('creditCard'))) {
				return;
			}
			if (prePaymentChecks && !(await prePaymentChecks('creditCard'))) {
				/*
					If the replace payment method confirmation modal is declined by the user,
					show no error, do not process the payment
				*/
				return;
			}

			try {
				if (!stripe || !elements) throw new Error('Failed to load Stripe library');

				setValidationErrors(DEFAULT_STRIPE_ERROR_VALIDATION);

				const createResult = await createStripePaymentMethod(stripe, elements, name, email);
				if (createResult.error) {
					onStripeError(createResult.error);
					return;
				}

				if (paymentMethodSaveOptions.shouldSavePaymentMethod) {
					const { accountId, clientSecret, paymentMethodId } = await setupStripePaymentMethod(
						createResult.paymentMethod,
						paymentMethodSaveOptions.usage,
						utilityContext,
						// Pass countryCode and currency to retrieve correct accountId from gateway router
						'creditCard',
						countryCode,
						currency,
					);

					// paymentMethodId is null when payment method is setup on platform account
					if (paymentMethodId && paymentMethodId !== null) createResult.paymentMethod.id = paymentMethodId;

					const stripeObj = await loadStripeInstance(stripe, accountId);

					const confirmError = await confirmStripeCardSetup(stripeObj, createResult.paymentMethod, clientSecret);
					if (confirmError) {
						onStripeError(confirmError);
						return;
					}

					await saveStripePaymentMethod(
						createResult.paymentMethod.id,
						email,
						paymentMethodSaveOptions.usage,
						utilityContext,
						'creditCard',
						accountId ?? undefined,
					);
				}

				const cardType = createResult.paymentMethod.card?.brand ?? undefined;

				void withToken({ paymentType: 'creditCard', token: createResult.paymentMethod.id, cardType });
			} catch (error) {
				handleSubmitFailure(error);
			}
		},
		[
			beforeToken,
			validate,
			prePaymentChecks,
			stripe,
			elements,
			name,
			email,
			paymentMethodSaveOptions.shouldSavePaymentMethod,
			paymentMethodSaveOptions.usage,
			withToken,
			onStripeError,
			utilityContext,
			countryCode,
			currency,
			loadStripeInstance,
			handleSubmitFailure,
		],
	);

	return {
		data: {
			errorMessage,
			validationErrors,
		},
		actions: {
			handleSubmit,
			validateStripeElementOnChange,
		},
	};
}
