import { isValidPhoneNumber } from 'libphonenumber-js/min';

import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';

type PhoneNumberValidationArgs = {
	value?: string;
	memberPhoneCollection: boolean;
	isOptional?: boolean;
};

export function validatePhoneNumber({
	value,
	memberPhoneCollection,
	isOptional,
}: PhoneNumberValidationArgs): TranslationKey | null {
	if (!memberPhoneCollection) return null;
	if (!value && isOptional) return null;
	if (!value || !isValidPhoneNumber(value)) return translationKey('corgi.membership.payment_form.error.valid_number');

	return null;
}
