import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	CreatePpmuMembershipEnrollmentMutation,
	CreatePpmuMembershipEnrollmentMutationVariables,
} from './api.graphql';

const query = gql`
	mutation CreatePpmuMembershipEnrollment(
		$subscriptionInput: CreateSubscriptionInput!
		$membershipEnrollmentInput: CreateMembershipEnrollmentInput!
	) {
		createMembershipEnrollment(
			subscriptionInput: $subscriptionInput
			membershipEnrollmentInput: $membershipEnrollmentInput
		) {
			__typename
			... on CreateMembershipEnrollmentError {
				message
				type
			}
		}
	}
`;

export type PpmuMembershipEnrollmentInput = Omit<
	CreatePpmuMembershipEnrollmentMutationVariables['subscriptionInput'],
	'productType'
> & {
	membershipData: CreatePpmuMembershipEnrollmentMutationVariables['membershipEnrollmentInput'];
};

export async function createPpmuMembershipEnrollment(
	{ membershipData, ...subscriptionInput }: PpmuMembershipEnrollmentInput,
	{ gql: { fetch } }: UtilityContext,
): Promise<CreatePpmuMembershipEnrollmentMutation['createMembershipEnrollment']> {
	const { data, errors } = await fetch<
		CreatePpmuMembershipEnrollmentMutation,
		CreatePpmuMembershipEnrollmentMutationVariables
	>({
		query,
		variables: {
			subscriptionInput: {
				...subscriptionInput,
				productType: 'MEMBERSHIP',
			},
			membershipEnrollmentInput: membershipData,
		},
		path: '/payments/createMembershipEnrollment',
		important: true, // to ensure this rate limited mutation is not batched
	});

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('Empty data in createPpmuMembershipEnrollment graphQL response');
	if (data.createMembershipEnrollment.__typename !== 'CreateMembershipEnrollmentSuccess')
		// FIXME throw error with message
		// eslint-disable-next-line @typescript-eslint/only-throw-error
		throw data.createMembershipEnrollment;

	return data.createMembershipEnrollment;
}
