import type { JSX } from 'react';

import { PaymentRequestButton } from 'src/app/shared/components/payments';

import type { ModalCallbackProps } from '../../shared/types';

import { useStripePaymentRequestButton } from './hooks/useStripePaymentRequestButton';

export function StripePaymentRequestButton(modalCallbackProps: ModalCallbackProps): JSX.Element | null {
	const {
		data: { testId, paymentType, displayed, ...dataProps },
		actions,
	} = useStripePaymentRequestButton(modalCallbackProps);

	if (!displayed || !paymentType) return null;

	return <PaymentRequestButton paymentType={paymentType} {...dataProps} {...actions} data-testid={testId} />;
}
