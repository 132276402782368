import type { PaymentType } from 'src/app/shared/utils/payments';

import { useMembershipModalState } from '../../modalContext';

export function useOnTokenInvalid(): (paymentType: PaymentType, err: Error) => void {
	const [, { clearLoading }] = useMembershipModalState();

	return function onTokenInvalid(_paymentType, _err): void {
		clearLoading();
	};
}
