import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useCountryCode } from 'src/app/shared/hooks/l10n';
import { getConsentInputFromPendingSigInLocalStorage } from 'src/app/shared/utils/payments';

import { useMembershipModalState } from '../../modalContext';

import { createPpmuMembershipEnrollment } from './api';

export function useCreateMembership(): (token?: string) => Promise<void> {
	const utilityContext = useUtilityContext();
	const countryCode = useCountryCode();
	const { amountToBaseUnits } = useI18n();

	const [
		{ email, firstName, lastName, gateway, membershipProgramId, petitionId, amount, currencyCode, tokenizationData },
	] = useMembershipModalState();

	return async (token?: string): Promise<void> => {
		const tokenInRequest = {
			...(tokenizationData?.token ? { gatewayToken: tokenizationData.token } : {}),
			...(token ? { gatewayToken: token } : {}),
		};

		await createPpmuMembershipEnrollment(
			{
				customer: {
					countryCode,
					email,
				},
				membershipData: {
					consentResponse: petitionId ? getConsentInputFromPendingSigInLocalStorage(petitionId) : undefined,
					firstName,
					lastName,
					membershipProgramId,
					source: 'POST_PROMOTE_UPSELL_DECOUPLED',
				},
				paymentMethod: {
					gateway,
					...tokenInRequest,
				},
				plan: {
					amount: amountToBaseUnits(amount, currencyCode),
					currencyCode,
					interval: 'MONTH',
					trialPeriodDays: 30,
				},
			},
			utilityContext,
		);
	};
}
