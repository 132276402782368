export type UserAlreadyExistsError = {
	userId: string;
	userPasswordSet: boolean;
};

export function isUserAlreadyExistsError(error: unknown): error is UserAlreadyExistsError {
	return (
		typeof error === 'object' &&
		error !== null &&
		'__typename' in error &&
		error.__typename === 'UserAlreadyExistsError'
	);
}
