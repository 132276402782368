import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Box } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useMembershipModalState } from '../modalContext/modalState';

export function Header(): JSX.Element {
	const [{ useDonationLanguage }] = useMembershipModalState();
	const session = useSessionAsync();

	const getSuffix = () => {
		return useDonationLanguage ? '_donation' : '';
	};

	const headlineSuffix = isLoaded(session) && session.value.loginState === 'GUEST' ? '_guest' : '';
	const name = isLoaded(session) ? session.value.user?.shortDisplayName : '';

	return (
		<>
			<Box sx={{ mb: 16 }}>
				<Heading size="h3" data-qa="ppmu-modal-header-title">
					<Translate value={`corgi.co.ppmu.modal.headline${headlineSuffix}`} replacements={{ name: name ?? '' }} />
				</Heading>
			</Box>
			<Text data-qa="ppmu-modal-header-description">
				<Translate value={`corgi.co.ppmu.modal.description${getSuffix()}`} />
			</Text>
		</>
	);
}
