import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { PaymentGateway } from './types';

function getStripeBaseUrl({ environment: { getEnvironment } }: UtilityContext) {
	const isProduction = getEnvironment() === 'production';
	return `https://dashboard.stripe.com${isProduction ? '' : '/test'}`;
}

function getBraintreeBaseUrl({ environment: { getEnvironment } }: UtilityContext) {
	const isProduction = getEnvironment() === 'production';
	return isProduction
		? 'https://www.braintreegateway.com/merchants/y34p8wzb427rdfqv'
		: 'https://sandbox.braintreegateway.com/merchants/bs5p34gd74r5899k';
}

export function getGatewayChargeUrl(
	gateway: PaymentGateway,
	gatewayChargeId: string,
	utilityContext: UtilityContext,
): string {
	switch (gateway) {
		case 'BRAINTREE':
			return `${getBraintreeBaseUrl(utilityContext)}/transactions/${gatewayChargeId}`;
		case 'STRIPE':
		default:
			return `${getStripeBaseUrl(utilityContext)}/payments/${gatewayChargeId}`;
	}
}

export function getGatewaySubscriptionUrl(
	gateway: PaymentGateway,
	gatewaySubscriptionId: string,
	utilityContext: UtilityContext,
): string {
	switch (gateway) {
		case 'BRAINTREE':
			return `${getBraintreeBaseUrl(utilityContext)}/subscriptions/${gatewaySubscriptionId}`;
		case 'STRIPE':
		default:
			return `${getStripeBaseUrl(utilityContext)}/subscriptions/${gatewaySubscriptionId}`;
	}
}
