type PaymentMethod = {
	document?: string;
	gateway: 'DLOCAL';
	token?: string | null;
	paymentMethodId?: string;
	paymentMethodFlow: 'DIRECT' | 'REDIRECT';
};

export function paymentMethod(
	paymentMethodId: string,
	nationalId: string | undefined,
	token?: string,
	useSavedPaymentMethod?: boolean,
): PaymentMethod {
	return {
		document: nationalId || undefined,
		gateway: 'DLOCAL',
		token,
		paymentMethodId: !paymentMethodId ? undefined : paymentMethodId.toUpperCase(),
		paymentMethodFlow: token || useSavedPaymentMethod ? 'DIRECT' : 'REDIRECT',
	};
}
