import { useI18n } from '@change-corgi/core/react/i18n';
import { Input } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

export function EmailInput({
	email,
	validationErrors,
	setEmail,
	trackEmailInputFocus,
	displayed,
}: {
	email: string;
	validationErrors: Record<string, string> | null;
	setEmail: (email: string) => void;
	trackEmailInputFocus: () => void;
	displayed: boolean;
}): React.JSX.Element | null {
	const { translate } = useI18n();

	if (!displayed) return null;

	return (
		<Box mb={8}>
			<Input
				error={validationErrors?.email}
				label={translate('corgi.co.promote.email_label')}
				onChange={(e) => setEmail(e.currentTarget.value)}
				type="email"
				value={email}
				data-qa="input-email"
				id="email-input"
				aria-label={translate('corgi.co.promote.email_label')}
				onFocus={() => trackEmailInputFocus()}
			/>
		</Box>
	);
}
