import type { PaymentMethod, Stripe, StripeError } from '@stripe/stripe-js';

export async function confirmStripeCardSetup(
	stripe: Stripe,
	paymentMethod: Partial<Pick<PaymentMethod, 'id'>>,
	clientSecret: string,
): Promise<StripeError | undefined> {
	const { error } = await stripe.confirmCardSetup(clientSecret, {
		payment_method: paymentMethod.id,
	});

	return error;
}
