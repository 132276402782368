import type { PaymentMethod } from '@stripe/stripe-js';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { StripeSetupIntent } from 'src/app/shared/api/payments';
import { setupPaymentMethod } from 'src/app/shared/api/payments';

import { gqlPaymentType } from '../../gqlPaymentType';
import type { PaymentType } from '../../types';

// eslint-disable-next-line max-params
export async function setupStripePaymentMethod(
	paymentMethod: Partial<Pick<PaymentMethod, 'id'>>,
	usage: 'OFF_SESSION' | 'ON_SESSION',
	utilityContext: UtilityContext,
	paymentType: PaymentType,
	countryCode?: string | null,
	currency?: string | null,
	accountId?: string,
): Promise<StripeSetupIntent> {
	const setupPaymentMethodResp = await setupPaymentMethod({
		input: {
			gateway: 'STRIPE',
			usage,
			token: paymentMethod.id,
			customer: {
				countryCode: countryCode ?? null,
				currencyCode: currency ?? null,
				billingName: null,
			},
			type: gqlPaymentType(paymentType),
			...(accountId && { stripe: { accountId } }),
		},
		utilityContext,
	});

	if (!setupPaymentMethodResp?.gatewayData) throw new Error('Error retrieving stripe setup intent');
	if (setupPaymentMethodResp.gatewayData.__typename !== 'SetupPaymentMethodStripeGatewayData')
		throw new Error('Error retrieving stripe setup intent');

	const stripeGatewayData = setupPaymentMethodResp?.gatewayData;

	if (!stripeGatewayData?.setupIntent) throw new Error('Error retrieving stripe setup intent');

	return stripeGatewayData.setupIntent;
}
