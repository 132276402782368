import type { JSX } from 'react';

import { PaypalButton } from 'src/app/shared/components/payments';

import { useShowPaypalButton } from '../../hooks/useShowPaypalButton';
import type { ModalCallbackProps } from '../../shared/types';

import { useNewPaymentMethodPaypal } from './hooks/useNewPaymentMethodPaypal';

export function NewPaymentMethodPaypal(modalCallbackProps: ModalCallbackProps): JSX.Element | null {
	const { data, actions } = useNewPaymentMethodPaypal(modalCallbackProps);

	const showPaypalButton = useShowPaypalButton();

	if (!showPaypalButton) return null;

	return <PaypalButton {...data} {...actions} style={{ color: 'blue' }} data-testid="paypal-button" />;
}
