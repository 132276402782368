import type { Stripe, StripeError, PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js';

import type { PaymentType } from '@change-corgi/middle-end/gql';

import { confirmStripeCardPayment } from './confirmStripeCardPayment';
import { confirmStripeCardSetup } from './confirmStripeCardSetup';
import { confirmStripeSepaDebitPayment } from './confirmStripeSepaDebitPayment';
import { confirmStripeSepaDebitSetup } from './confirmStripeSepaDebitSetup';
import { confirmStripeUpiPayment } from './confirmStripeUpiPayment';

type PaymentConfirmationFunction = (
	stripe: Stripe,
	paymentMethod: string | undefined,
	clientSecret: string,
) => Promise<StripeError | undefined>;

type SetupConfirmationFunction = (
	stripe: Stripe,
	paymentMethod: Partial<Pick<StripePaymentMethod, 'id'>>,
	clientSecret: string,
) => Promise<StripeError | undefined>;

export async function noop(
	_stripe: Stripe,
	_paymentMethod: Partial<Pick<StripePaymentMethod, 'id'>> | string | undefined,
	_clientSecret: string,
): Promise<StripeError | undefined> {
	return Promise.resolve(undefined);
}

const paymentConfirmationHandlerMap: Record<PaymentType, PaymentConfirmationFunction> = {
	APPLE_PAY: confirmStripeCardPayment,
	GOOGLE_PAY: confirmStripeCardPayment,
	CREDIT_CARD: confirmStripeCardPayment,
	SEPA_DEBIT: confirmStripeSepaDebitPayment,
	SEPA: confirmStripeSepaDebitPayment,
	UPI: confirmStripeUpiPayment,
	PAYPAL: noop,
};

const setupConfirmationHandler: Record<PaymentType, SetupConfirmationFunction> = {
	APPLE_PAY: confirmStripeCardSetup,
	GOOGLE_PAY: confirmStripeCardSetup,
	CREDIT_CARD: confirmStripeCardSetup,
	SEPA_DEBIT: confirmStripeSepaDebitSetup,
	SEPA: confirmStripeSepaDebitSetup,
	UPI: noop,
	PAYPAL: noop,
};

export function getPaymentConfirmationHandler(paymentType: PaymentType): PaymentConfirmationFunction {
	return paymentConfirmationHandlerMap[paymentType];
}

export function getSetupConfirmationHandler(paymentType: PaymentType): SetupConfirmationFunction {
	return setupConfirmationHandler[paymentType];
}
