import type { Stripe, StripeError } from '@stripe/stripe-js';

export async function confirmStripeSepaDebitPayment(
	stripe: Stripe,
	paymentMethod: string | undefined,
	clientSecret: string,
): Promise<StripeError | undefined> {
	const { error } = await stripe.confirmSepaDebitPayment(clientSecret, {
		payment_method: paymentMethod,
	});

	return error;
}
