import type { PaymentMethodResult, Stripe, StripeElements } from '@stripe/stripe-js';

export async function createStripeSepaDebitPayment(
	stripe: Stripe,
	elements: StripeElements,
	name: string,
	email: string,
): Promise<PaymentMethodResult> {
	const ibanElement = elements.getElement('iban');
	if (!ibanElement) throw new Error('No iban element found');

	return stripe.createPaymentMethod({
		type: 'sepa_debit',
		sepa_debit: ibanElement,
		billing_details: {
			name,
			email,
		},
	});
}
