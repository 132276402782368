import type { StripeError } from '@stripe/stripe-js';

import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';

const STRIPE_CARD_DECLINE_CODES = ['fraudulent', 'transaction_not_allowed'];

const STRIPE_ERROR_TYPES = ['api_connection_error'];

const STRIPE_CARD_ERROR_CODES = [
	'card_declined',
	'expired_card',
	'incomplete_number',
	'incomplete_cvc',
	'incomplete_expiry',
	'incorrect_cvc',
	'incorrect_number',
	'incorrect_zip',
	'invalid_cvc',
	'invalid_expiry_month',
	'invalid_expiry_month_past',
	'invalid_expiry_year',
	'invalid_expiry_year_past',
	'invalid_number',
	'missing',
	'processing_error',
];

const STRIPE_SEPA_ERROR_CODES = [
	'incomplete_iban',
	'payment_method_not_available',
	'invalid_bank_account_iban',
	'invalid_owner_name',
];

export function stripeErrorI18nKey({
	code,
	decline_code: declineCode = undefined,
	type,
}: StripeError): TranslationKey | undefined {
	if (STRIPE_ERROR_TYPES.includes(type)) return translationKey(`corgi.payments.stripe_error.${type}`);

	if (!code) return undefined;

	if (STRIPE_CARD_ERROR_CODES.includes(code)) {
		if (code === 'card_declined' && declineCode && STRIPE_CARD_DECLINE_CODES.includes(declineCode)) {
			return translationKey(`fe.errors.credit_card.stripe.${declineCode}`);
		}
		return translationKey(`corgi.payments.stripe_error.${code}`);
	}
	if (STRIPE_SEPA_ERROR_CODES.includes(code)) {
		if (code === 'incomplete_iban') {
			return translationKey('fe.errors.credit_card.account_number');
		}
		return translationKey(`fe.errors.sepa.${code}`);
	}
	return translationKey('fe.errors.credit_card.transaction_error');
}
