import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';

import { SubmitButton } from 'src/app/shared/components/payments';

import { useShowPaypalButton } from '../hooks/useShowPaypalButton';
import { useShowStripePaymentRequestButton } from '../hooks/useShowStripePaymentRequestButton';
import { useMembershipModalState } from '../modalContext';

export function ContinueButton(): JSX.Element | null {
	const [{ formState }] = useMembershipModalState();
	const showPaypalButton = useShowPaypalButton();
	const showStripePaymentRequestButton = useShowStripePaymentRequestButton();

	if (showPaypalButton) return null;
	if (showStripePaymentRequestButton) return null;

	const isLoading = formState?.status === 'loading';
	return (
		<SubmitButton loading={isLoading} disabled={isLoading} label={<Translate value="corgi.co.ppmu.modal.continue" />} />
	);
}
