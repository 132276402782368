import type { WithTokenParams } from 'src/app/shared/types/index.js';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import type { NewPaymentMethodStripeProps } from '../../index.tsx';

import { useTracking } from './useTracking';

type Result = ModelHookResult<
	{
		email: string;
		name: string;
		paymentMethodSaveOptions: PaymentMethodSaveOptions;
	},
	{
		beforeToken: (paymentType: PaymentType) => Promise<boolean>;
		onTokenError: (paymentType: PaymentType, err: Error) => void;
		onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
		onCardCVCFieldFocus: () => void;
		onCardExpiryFieldFocus: () => void;
		onCardNumberFieldFocus: () => void;
		validate: (paymentType: PaymentType) => Promise<boolean>;
		withToken: (params: WithTokenParams) => Promise<void>;
		prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
	}
>;

export function useNewPaymentMethodStripe({
	email,
	name,
	paymentMethodSaveOptions,
	beforeToken,
	onTokenError,
	onTokenInvalid,
	withToken,
	validate,
	prePaymentChecks,
}: Omit<NewPaymentMethodStripeProps, 'submitButton' | 'countryStateSelect' | 'phoneNumberInput'>): Result {
	const { trackCardFieldFocus } = useTracking();

	return {
		data: {
			email,
			name,
			paymentMethodSaveOptions,
		},
		actions: {
			beforeToken,
			onTokenError,
			onTokenInvalid,
			onCardCVCFieldFocus: () => trackCardFieldFocus('cvc'),
			onCardExpiryFieldFocus: () => trackCardFieldFocus('expiry'),
			onCardNumberFieldFocus: () => trackCardFieldFocus('number'),
			validate,
			withToken,
			prePaymentChecks,
		},
	};
}
