import React, { cloneElement } from 'react';

import { FormError, Input } from '@change-corgi/design-system/components/forms';
import { Box, Flex } from '@change-corgi/design-system/layout';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentType } from 'src/app/shared/utils/payments';

import type { SubmitButtonProps } from '../../../index';

import { useUpiForm } from './hooks/useUpiForm';

type Props = {
	name: string;
	email: string;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	validate: (paymentType: PaymentType) => Promise<boolean>;
	prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	withToken: (params: WithTokenParams) => Promise<void>;
	onFieldFocus: () => void;
	submitButton: React.ReactElement<SubmitButtonProps>;
	vpa: string;
	setVpa: (vpa: string) => void;
	countryStateSelect: React.JSX.Element;
	vpaValidationError: string | undefined;
};

export function UpiForm({
	name,
	email,
	beforeToken,
	validate,
	prePaymentChecks,
	onTokenError,
	onTokenInvalid,
	withToken,
	onFieldFocus,
	submitButton,
	vpa,
	setVpa,
	countryStateSelect,
	vpaValidationError,
}: Props): React.JSX.Element | null {
	const {
		data: { validationError, errorMessage, currentVpa, vpaLabel, vpaPlaceholder },
		actions: { handleSubmit, onVpaChange },
	} = useUpiForm({
		name,
		email,
		beforeToken,
		validate,
		prePaymentChecks,
		onTokenError,
		onTokenInvalid,
		withToken,
		vpa,
		setVpa,
		vpaValidationError,
	});

	return (
		<>
			<Box mb={16}>
				<form data-testid="upi-form" onSubmit={handleSubmit}>
					<Box mb={24}>
						<Input
							data-qa="upi-form-element"
							label={vpaLabel}
							name="vpa"
							error={validationError}
							onFocus={onFieldFocus}
							onChange={onVpaChange}
							placeholder={vpaPlaceholder}
							id="vpa"
							value={currentVpa}
						/>
						{countryStateSelect}
					</Box>

					<Flex
						sx={{
							justifyContent: 'center',
						}}
					>
						{cloneElement(submitButton, {
							disabled: submitButton.props.disabled,
						})}
					</Flex>
				</form>
			</Box>
			{errorMessage && (
				<FormError mb={16} data-qa="upi-form-error-message">
					{errorMessage}
				</FormError>
			)}
		</>
	);
}
