import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';
import type { LoginState } from '@change-corgi/middle-end/gql';

type LastNameValidationArgs = {
	lastName: string;
	loginState: LoginState;
};

export function validateLastName({ lastName, loginState }: LastNameValidationArgs): TranslationKey | null {
	// This line here to check for the conditions under which we actually display the last name field to the user,
	// since we can't get it from the user state.
	if (loginState !== 'GUEST') return null;

	if (!lastName.trim().length) return translationKey('fe.errors.credit_card.last_name'); // TODO: move i18n key to corgi namespace

	return null;
}
