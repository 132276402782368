import { useCallback } from 'react';

import { useTracking as useTrackingBase } from '@change-corgi/core/react/tracking';

type Result = {
	trackPaymentRequestCancel: () => void;
};

export function useTracking(): Result {
	const track = useTrackingBase();

	return {
		trackPaymentRequestCancel: useCallback(() => {
			void track('payment_request_cancel');
		}, [track]),
	};
}
