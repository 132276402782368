import { paymentMethod } from './paymentMethod';

import type { PaymentType } from './index';

type ShowSavePaymentMethodCheckbox = { stripe: boolean | undefined; paypal: boolean | undefined };

export function shouldSavePaymentMethod(
	showSavePaymentCheckbox: ShowSavePaymentMethodCheckbox,
	selectedPaymentType: PaymentType,
): boolean {
	const { gateway } = paymentMethod(selectedPaymentType);

	if (['applePay', 'googlePay', 'upi'].includes(selectedPaymentType)) return false;

	type PaymentTypeProperty = keyof typeof showSavePaymentCheckbox;
	if (selectedPaymentType in showSavePaymentCheckbox) {
		return !!showSavePaymentCheckbox[selectedPaymentType as PaymentTypeProperty];
	}
	return !!showSavePaymentCheckbox[gateway.toLowerCase() as PaymentTypeProperty];
}
