import React from 'react';

import type { PaymentType } from 'src/app/shared/utils/payments';

import type { SavedPaymentMethod } from '../../types';
import { PaymentSelectorButton } from '../PaymentSelectorButton';

import { useSavedPaymentMethodButton } from './hooks/useSavedPaymentMethodButton';

export type SavedPaymentMethodButtonProps = {
	paymentMethod: SavedPaymentMethod;
	selectedPaymentType: PaymentType;
	usingSavedPaymentMethod: boolean;
	onSelectSavedPaymentMethod: (selectedPaymentType: PaymentType) => void;
	isVariant?: boolean;
};

export function SavedPaymentMethodButton({
	paymentMethod,
	selectedPaymentType,
	usingSavedPaymentMethod,
	onSelectSavedPaymentMethod,
	isVariant,
}: SavedPaymentMethodButtonProps): React.JSX.Element {
	const { data } = useSavedPaymentMethodButton({
		paymentMethod,
		selectedPaymentType,
		usingSavedPaymentMethod,
	});

	return (
		<PaymentSelectorButton
			{...data}
			type={paymentMethod.type}
			onClick={() => onSelectSavedPaymentMethod(paymentMethod.type)}
			isVariant={isVariant}
		/>
	);
}
