import { setPxParamVariable } from 'src/app/shared/utils/perimeterx';

import { isStripeError } from './stripe/isStripeError';

export function setPxDeclineCode(error: unknown): void {
	if (!isStripeError(error)) return;

	if (error.type === 'card_error' && error.decline_code) {
		setPxParamVariable('paymentDeclineCode', error.decline_code);
	}
}
