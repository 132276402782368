import { useCountryCode } from 'src/app/shared/hooks/l10n';
import { useDLocalPermanentPaymentMethod } from 'src/app/shared/hooks/payments';

import { useMembershipModalState } from '../../modalContext';

import { useStripePermanentPaymentMethod } from './useStripePermanentPaymentMethod';

export function usePreparePaymentMethod(): (token?: string) => Promise<void> {
	const countryCode = useCountryCode();

	const [{ gateway, tokenizationData, email, usingSavedPaymentMethod, paymentType, currencyCode }] =
		useMembershipModalState();

	const prepareDLocalPermanentPaymentMethod = useDLocalPermanentPaymentMethod();

	const prepareStripePermanentPaymentMethod = useStripePermanentPaymentMethod();

	return async (token?: string): Promise<void> => {
		if (gateway === 'DLOCAL') {
			if (!tokenizationData) return;
			await prepareDLocalPermanentPaymentMethod({
				...tokenizationData,
				shouldSavePaymentMethod: !usingSavedPaymentMethod,
				customer: {
					email,
				},
				usage: 'OFF_SESSION',
			});
		} else if (gateway === 'STRIPE') {
			await prepareStripePermanentPaymentMethod({
				...tokenizationData,
				paymentType,
				usage: 'OFF_SESSION',
				email,
				countryCode,
				currencyCode,
				// new token is generated when payment type is apple pay or google pay
				// it should be used not the one from tokenizationData
				...(token ? { token, tokenType: 'temporary' } : {}),
			});
		} else if (gateway === 'BRAINTREE') {
			/* no-op for BRAINTREE */
		} else {
			throw new Error('Unsupported gateway');
		}
	};
}
