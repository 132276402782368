import React from 'react';

import type { PaymentType } from 'src/app/shared/utils/payments';

import { PaymentSelectorButton } from '../PaymentSelectorButton';

import { useNewPaymentMethodButton } from './hooks/useNewPaymentMethodButton';

export type NewPaymentMethodButtonProps = {
	usingSavedPaymentMethod: boolean;
	availableNewPaymentMethods: PaymentType[];
	onSelectNewPaymentMethod: (selectedPaymentType: PaymentType) => void;
};

export function NewPaymentMethodButton(props: NewPaymentMethodButtonProps): React.JSX.Element {
	const {
		data,
		actions: { setNewSelectedPaymentType },
	} = useNewPaymentMethodButton(props);

	return <PaymentSelectorButton {...data} onClick={setNewSelectedPaymentType} />;
}
