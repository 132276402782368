import type { Locale } from '@change-corgi/config/locales';
import { getPaypalLocale } from '@change-corgi/config/paypal';

import type { PaypalCheckout } from 'src/app/shared/utils/payments';

type CreateBillingAgreementParams = {
	currency: string;
	disabled: boolean;
	locale: Locale;
	paypalCheckout: PaypalCheckout | null;
	billingAgreementDescription?: string;
};

export async function createBillingAgreement({
	currency,
	disabled,
	locale,
	paypalCheckout,
	billingAgreementDescription,
}: CreateBillingAgreementParams): Promise<string> {
	if (!paypalCheckout) {
		return Promise.reject(new Error('No paypal checkout instance'));
	}

	if (disabled) return Promise.reject(new Error('Disabled'));

	return paypalCheckout.createPayment({
		billingAgreementDescription,
		currency,
		displayName: 'Change.org',
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		flow: 'vault' as paypal.FlowType.Vault,
		locale: getPaypalLocale(locale),
	});
}
