import type { PaymentMethodResult, Stripe } from '@stripe/stripe-js';

export async function createStripeUpiPayment(
	stripe: Stripe,
	name: string,
	email: string,
	vpa: string | undefined,
): Promise<PaymentMethodResult> {
	/*
	Casting the type since Stripe doesn't support private beta types currently.
	type 'upi' is not recognized by Stripe
	*/
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return stripe.createPaymentMethod({
		type: 'upi',
		upi: {
			vpa,
		},
		billing_details: {
			name,
			email,
		},
	} as never);
}
