import React from 'react';

import { stripHtmlTags } from '@change-corgi/core/html';
import { Translate, TranslatePlural } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';
import { Image } from '@change-corgi/design-system/components/media';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { Thermometer } from 'src/app/shared/components/signatures';

export type PetitionCardProps = {
	petition: {
		ask: string;
		description: string;
		photo?:
			| null
			| undefined
			| {
					petitionMedium: {
						url: string;
					};
			  };
		signatureCount: {
			displayed: number;
		};
		signatureGoal: {
			displayed: number;
		};
	};
};

export function PetitionCard({
	petition: {
		ask,
		description,
		signatureCount: { displayed },
		signatureGoal: { displayed: goal },
		photo,
	},
}: PetitionCardProps): React.JSX.Element {
	return (
		<Box
			variant="bordered"
			sx={{
				alignItems: 'center',
				bg: 'primary-white',
				p: 0,
				m: 0,
				mt: 16,
			}}
		>
			<Flex sx={{ flexDirection: 'column' }}>
				<Image
					aspectRatio="16/9"
					src={`${photo?.petitionMedium?.url || 'https://static.change.org/images/default-petition-darker.gif'}`}
					data-testid="petition-card-cover-image"
					data-qa="promote-petition-card-cover-image"
					alt=""
					fit="cover"
					sx={{ borderTopLeftRadius: 'standard', borderTopRightRadius: 'standard', width: '100%' }}
				/>
				<Thermometer signatureCount={displayed} signatureGoal={goal} height="large" rounded={false}>
					<TranslatePlural
						value="corgi.co.promote.petition_card.thermometer_label"
						count={displayed}
						replacements={{ supporterCount: displayed }}
					/>
				</Thermometer>
			</Flex>
			<Box p={16}>
				<Heading as="h4" mt={0} size={['h4', 'subtitle']} data-qa="promote-petition-card-heading">
					{ask}
				</Heading>
				<Text ellipsis as="div" sx={{ width: 'calc(100%)' }}>
					<Text as="p" mt={4} size="small" data-qa="promote-petition-card-description">
						{stripHtmlTags(description)}
					</Text>
				</Text>

				<Button
					variant="secondaryEmphasis"
					size="small"
					disabled
					sx={{ width: '100%' }}
					mt={16}
					data-qa="promote-petition-card-button"
				>
					<Translate value="corgi.co.promote.petition_card.button_label" />
				</Button>
			</Box>
		</Box>
	);
}
