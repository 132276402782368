import type { JSX } from 'react';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import { IbanForm } from '../../../../stripe/components/IbanForm';

import { useNewPaymentMethodSepa } from './hooks/useNewPaymentMethodSepa';

export type NewPaymentMethodSepaProps = {
	email: string;
	name: string;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	validate: (paymentType: PaymentType) => Promise<boolean>;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	withToken: (params: WithTokenParams) => Promise<void>;
	prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
	onFieldFocusIban: () => void;
	submitButton: React.JSX.Element;
};

export function NewPaymentMethodSepa({ submitButton, ...props }: NewPaymentMethodSepaProps): JSX.Element {
	const { data, actions } = useNewPaymentMethodSepa(props);
	return <IbanForm {...data} {...actions} submitButton={submitButton} />;
}
