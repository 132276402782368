import { useEffect, useState } from 'react';

import { useStripe } from '@stripe/react-stripe-js';
import type { CanMakePaymentResult, PaymentRequest } from '@stripe/stripe-js';

type Result = {
	applePay: boolean;
	googlePay: boolean;
};

export function useCanUsePaymentRequestButtonMethods(): Result {
	const stripe = useStripe();
	const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>(undefined);
	const [canMakePayment, setCanMakePayment] = useState<CanMakePaymentResult | null>(null);

	useEffect(() => {
		if (!paymentRequest) return;
		(async () => {
			const cmp = await paymentRequest.canMakePayment();
			setCanMakePayment(cmp);
		})();
	}, [paymentRequest]);

	if (!stripe) return { applePay: false, googlePay: false };

	if (stripe && !paymentRequest) {
		const pr = stripe.paymentRequest({
			country: 'US',
			currency: 'usd',
			total: {
				// This data is not used but required by stripe to initialize the payment request.
				label: 'Demo total',
				amount: 0,
			},
			requestPayerName: true,
			requestPayerEmail: true,
		});
		setPaymentRequest(pr);
	}

	return { applePay: !!canMakePayment?.applePay, googlePay: !!canMakePayment?.googlePay };
}
