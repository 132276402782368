import type { PaymentType } from 'src/app/shared/utils/payments';

import { useMembershipModalState } from '../../modalContext';

export function useBeforeToken(): (paymentType: PaymentType) => Promise<boolean> {
	const [, { clearGeneralErrorMessage, setLoading }] = useMembershipModalState();

	return async function beforeToken(_paymentType): Promise<boolean> {
		clearGeneralErrorMessage();
		setLoading();
		return Promise.resolve(true);
	};
}
