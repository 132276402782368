import type { ChangeEvent, JSX, PropsWithChildren } from 'react';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { Input } from '@change-corgi/design-system/components/forms';
import { Image } from '@change-corgi/design-system/components/media';
import { Box, Flex } from '@change-corgi/design-system/layout';

import { InfoTooltip } from 'src/app/shared/components/tooltips';

import { DlocalButton } from './DlocalButton';
import type { DLocalValidationError } from './types';

type Props = {
	visible: boolean;
	handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
	cardHolder: string | undefined;
	onChangeCardHolderName: (event: ChangeEvent<HTMLInputElement>) => void;
	error: DLocalValidationError;
	cardNumberElement: React.ComponentType<unknown>;
	cardExpiryElement: React.ComponentType<unknown>;
	cardCVVElement: React.ComponentType<unknown>;
	disabled: boolean;
	loading: boolean;
	onCardHolderNameFocus: () => void;
};

// eslint-disable-next-line max-lines-per-function
export function DLocalCreditCardForm({
	visible,
	handleSubmit,
	cardHolder,
	children,
	onChangeCardHolderName,
	error,
	cardNumberElement,
	cardExpiryElement,
	cardCVVElement,
	disabled,
	loading,
	onCardHolderNameFocus,
}: PropsWithChildren<Props>): JSX.Element {
	const { translate } = useI18n();

	return (
		<form
			sx={{ display: visible ? 'unset' : 'none' }}
			data-testid="dlocal-credit-card-form"
			onSubmit={handleSubmit}
			data-qa="dlocal-credit-card-form"
		>
			<Box
				sx={{ position: 'relative' }}
				mb={24}
				data-testid="card-holder"
				data-qa="dlocal-credit-card-form-cardholder-name"
			>
				<Input
					label={translate('fe.components.credit_card.cardholder_name')}
					name="card-holder-name"
					value={cardHolder}
					onChange={onChangeCardHolderName}
					onFocus={onCardHolderNameFocus}
					data-qa="dlocal-credit-card-form-cardholder-name-input"
				/>
			</Box>
			<Box
				sx={{ position: 'relative' }}
				mb={24}
				data-testid="credit-card-number"
				data-qa="dlocal-credit-card-form-card-number"
			>
				<Input
					label={translate('corgi.payments.stripe_elements.card_number')}
					name="card-number"
					error={error.card}
					as={cardNumberElement}
				/>
				<Box sx={{ position: 'absolute', right: 0, top: 0 }}>
					<Image
						// FIXME: Add an alt label
						alt=""
						sx={{ height: '20px', width: '127px' }}
						src="https://assets.change.org/app-assets/victory_fund/credit-card-icons.png"
						data-qa="dlocal-credit-card-form-card-image"
					/>
				</Box>
			</Box>
			<Flex>
				<Box sx={{ flex: '1' }} mr={8} data-testid="credit-card-expiry" data-qa="dlocal-credit-card-form-card-expiry">
					<Input
						label={translate('corgi.payments.stripe_elements.expiration')}
						name="card-expiration"
						error={error.expire}
						as={cardExpiryElement}
					/>
				</Box>
				<Box sx={{ flex: '1' }} data-testid="credit-card-cvc" data-qa="dlocal-credit-card-form-card-cvc">
					<Input
						label={
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(
								// Grabbed this value from: src/app/shared/components/payments/stripe/components/StripeCreditCardElements.tsx
								<Box sx={{ height: 20 }}>
									<Translate value="corgi.payments.stripe_elements.cvc" />{' '}
									<Box sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
										<InfoTooltip data-testid="credit-card-cvc-info" data-qa="dlocal-credit-card-form-card-cvc-tooltip">
											{translate('corgi.payments.stripe_elements.cvc_tooltip')}
										</InfoTooltip>
									</Box>
								</Box>
							) as unknown as string
						}
						name="card-cvc"
						error={error.cvv}
						as={cardCVVElement}
					/>
				</Box>
			</Flex>
			{children}
			<Box mt={16}>
				<DlocalButton disabled={disabled || loading} loading={loading} />
			</Box>
		</form>
	);
}
