import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	FetchBraintreeClientDataQuery,
	FetchBraintreeClientDataQueryVariables,
} from './fetchBraintreeClientData.graphql';
import { FetchBraintreeClientData } from './fetchBraintreeClientData.graphql';

export type BraintreeClientData = {
	environment: string;
	clientToken: string;
};

export async function fetchBraintreeClientData({
	gql: { fetch },
	errorReporter,
}: UtilityContext): Promise<BraintreeClientData> {
	try {
		const { data } = await fetch<FetchBraintreeClientDataQuery, FetchBraintreeClientDataQueryVariables>({
			query: FetchBraintreeClientData,
			rejectOnError: true,
		});

		if (!data?.braintreeClientToken) {
			throw new Error('Error retrieving the braintree token');
		}

		return data.braintreeClientToken;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		void errorReporter.report(
			{
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
				error: new Error(e.message ?? e.statusText ?? 'Unexpected error'),
			},
			{
				severity: 'warning',
			},
		);
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
		throw new Error(e.message || e.statusText || 'Unexpected error');
	}
}
