import { useI18n } from '@change-corgi/core/react/i18n';
import { Input } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

export function NameInput({
	firstName,
	lastName,
	name,
	displayed,
	validationErrors,
	useSplitFields,
	label,
	setFirstName,
	setLastName,
	setName,
	trackNameInputFocus,
	trackFirstNameInputFocus,
	trackLastNameInputFocus,
}: {
	firstName: string;
	lastName: string;
	name: string;
	displayed: boolean;
	validationErrors: Record<string, string> | null;
	useSplitFields: boolean;
	label: string;
	setFirstName: (firstName: string) => void;
	setLastName: (lastName: string) => void;
	setName: (name: string) => void;
	trackNameInputFocus: () => void;
	trackFirstNameInputFocus: () => void;
	trackLastNameInputFocus: () => void;
}): React.JSX.Element | null {
	const { translate } = useI18n();

	if (!displayed) return null;

	if (useSplitFields)
		return (
			<Box mb={8}>
				<Box mb={8}>
					<Input
						error={validationErrors?.firstName}
						label={label}
						onChange={(e) => setFirstName(e.currentTarget.value)}
						onFocus={() => trackFirstNameInputFocus()}
						placeholder={translate('fe.components.credit_card.first_name')} // TODO: move i18n label to corgi namespace
						type="text"
						value={firstName}
						data-qa="input-first-name"
						id="cardholder-firstName-input"
					/>
				</Box>

				<Input
					aria-label={translate('fe.components.credit_card.last_name')}
					error={validationErrors?.lastName}
					onChange={(e) => setLastName(e.currentTarget.value)}
					onFocus={() => trackLastNameInputFocus()}
					placeholder={translate('fe.components.credit_card.last_name')} // TODO: move i18n label to corgi namespace
					type="text"
					value={lastName}
					data-qa="input-last-name"
					id="cardholder-lastName-input"
					sx={{ marginTop: validationErrors?.firstName ? '8' : '0' }}
				/>
			</Box>
		);

	return (
		<Input
			mb={8}
			error={validationErrors?.name}
			label={label}
			onChange={(e) => setName(e.currentTarget.value)}
			onFocus={() => trackNameInputFocus()}
			type="text"
			value={name}
			data-qa="input-cardholder-name"
			id="cardholder-name-input"
		/>
	);
}
