import type { PaymentType, SavedPaymentMethod } from './index';

const STRIPE_BRAND_MAP: Record<string, string> = {
	amex: 'American Express',
	diners: 'Diners Club',
	discover: 'Discover',
	jcb: 'JCB',
	mastercard: 'MasterCard',
	unionpay: 'UnionPay',
	visa: 'Visa',
	unknown: 'Unknown',
};

export function getStripeCardDisplayName(
	cardType: string | undefined,
	savedPaymentMethods: SavedPaymentMethod[] | undefined,
	usingSavedPaymentMethod: boolean,
	selectedPaymentType: PaymentType,
): string {
	if (cardType) {
		return STRIPE_BRAND_MAP[cardType] || cardType;
	}
	if (usingSavedPaymentMethod && selectedPaymentType === 'creditCard') {
		const savedBrand =
			(
				savedPaymentMethods?.find((paymentMethod) => paymentMethod.type === 'creditCard') as
					| { brand: string }
					| undefined
			)?.brand || 'unknown';
		return STRIPE_BRAND_MAP[savedBrand] || savedBrand;
	}

	return 'Unknown';
}
