import React from 'react';

import { IbanElement } from '@stripe/react-stripe-js';

import { useCountryCode } from 'src/app/shared/hooks/l10n';

export function IbanElementWrapper(props: Record<string, unknown>): React.JSX.Element {
	const countryCode = useCountryCode();
	return (
		<IbanElement
			{...props}
			options={{
				supportedCountries: ['SEPA'],
				placeholderCountry: countryCode,
				style: { base: { fontSize: '16px' } },
			}}
		/>
	);
}
