import { useStripe } from '@stripe/react-stripe-js';
import type { PaymentMethod } from '@stripe/stripe-js';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import type { PaymentMethodUsage } from '@change-corgi/middle-end/gql';

import { useLoadStripe } from 'src/app/shared/components/payments';
import type { PaymentType } from 'src/app/shared/utils/payments';
import { getSetupConfirmationHandler, gqlPaymentType, setupStripePaymentMethod } from 'src/app/shared/utils/payments';

export function useSetupAndConfirm(): (params: {
	paymentMethod: Partial<Pick<PaymentMethod, 'id'>>;
	usage: PaymentMethodUsage;
	paymentType: PaymentType;
	countryCode: string;
	currencyCode: string;
	accountId?: string;
}) => Promise<void> {
	const initialStripeInstance = useStripe();
	const utilityContext = useUtilityContext();
	const loadStripe = useLoadStripe();

	return async function setupAndConfirm({ paymentMethod, usage, paymentType, countryCode, currencyCode, accountId }) {
		const { clientSecret, accountId: setupAccountId } = await setupStripePaymentMethod(
			paymentMethod,
			usage,
			utilityContext,
			paymentType,
			countryCode,
			currencyCode,
			accountId,
		);
		const stripe = await loadStripe(initialStripeInstance, setupAccountId);
		const handler = getSetupConfirmationHandler(gqlPaymentType(paymentType));
		const confirmError = await handler(stripe, paymentMethod, clientSecret);
		if (confirmError) {
			throw new Error(confirmError.message);
		}
	};
}
