import type { JSX } from 'react';

import type { TokenizationData } from 'src/app/shared/utils/payments';

import { SubmitButton } from './SubmitButton';

type Props = {
	disabled: boolean;
	loading: boolean;
	onClick?: (paymentType: string, tokenizationData?: TokenizationData) => void;
};

export function DlocalButton({ disabled, loading, onClick }: Props): JSX.Element {
	function handleClick() {
		if (onClick) {
			onClick('dlocal');
		}
	}

	return <SubmitButton disabled={disabled} loading={loading} onClick={handleClick} />;
}
