import type { PaymentMethod, Stripe, StripeError } from '@stripe/stripe-js';

export async function confirmStripeSepaDebitSetup(
	stripe: Stripe,
	paymentMethod: Partial<Pick<PaymentMethod, 'id'>>,
	clientSecret: string,
): Promise<StripeError | undefined> {
	const { error } = await stripe.confirmSepaDebitSetup(clientSecret, {
		payment_method: paymentMethod.id,
	});

	return error;
}
