import isEmail from 'validator/lib/isEmail';

import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';
import type { LoginState } from '@change-corgi/middle-end/gql';

type EmailValidationArgs = {
	email: string;
	loginState: LoginState;
};

export function validateEmail({ email, loginState }: EmailValidationArgs): TranslationKey | null {
	// This line here to check for the conditions under which we actually display the email field to the user,
	// since we can't get it from the user state.
	if (loginState !== 'GUEST') return null;

	if (isEmail(email)) return null;

	return translationKey('fe.errors.credit_card.email'); // TODO: move i18n key to corgi namespace
}
