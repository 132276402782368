import type { JSX } from 'react';

import { TranslateHtml } from '@change-corgi/core/react/i18n';
import { Image } from '@change-corgi/design-system/components/media';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

export function PayWithCreditCardOrPaypalText(): JSX.Element {
	return (
		<Flex sx={{ justifyContent: 'center' }}>
			<Text color="neutral-grey600">
				<TranslateHtml
					value="corgi.membership.ask.pay_with"
					replacements={{
						paypalImageElement: (
							<Image
								alt="paypal"
								sx={{ height: [18, 20], display: 'inline' }}
								mb={-4}
								src="https://static.change.org/payment-options/paypal-wordmark.png"
							/>
						),
					}}
				/>
			</Text>
		</Flex>
	);
}
