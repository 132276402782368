import type { PaymentType as GQLPaymentType } from '@change-corgi/middle-end/gql';

import type { PaymentType } from './types';

const paymentTypeMap: Record<PaymentType, GQLPaymentType> = {
	applePay: 'APPLE_PAY',
	creditCard: 'CREDIT_CARD',
	googlePay: 'GOOGLE_PAY',
	paypal: 'PAYPAL',
	sepa: 'SEPA_DEBIT',
	upi: 'UPI',
};

export function gqlPaymentType(paymentType: PaymentType): GQLPaymentType {
	return paymentTypeMap[paymentType];
}
