import md5 from 'crypto-js/md5';

import { setPxParamVariable } from 'src/app/shared/utils/perimeterx';

const emailDomain = (email: string): string => email.split('@')[1];
const hash = (str: string): string => md5(str).toString();

type PxUserData = {
	id?: string;
	email?: string;
	createdAt?: string | null;
};

export function setPxUserData(user?: PxUserData): void {
	if (!user) return;

	const { id: userId, email, createdAt } = user;

	if (userId) setPxParamVariable('userId', userId);
	if (email) {
		setPxParamVariable('emailDomain', emailDomain(email));
		setPxParamVariable('emailHash', hash(email));
	}
	if (createdAt) setPxParamVariable('accountDate', createdAt);
}
