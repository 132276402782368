import { useCallback } from 'react';

import { useTracking as useTrackingBase } from '@change-corgi/core/react/tracking';

type Result = {
	trackCardFieldFocus: (field: 'cvc' | 'expiry' | 'number') => void;
};

export function useTracking(): Result {
	const track = useTrackingBase();

	return {
		trackCardFieldFocus: useCallback(
			(field: 'cvc' | 'expiry' | 'number') => {
				void track(`pp_focus_card_${field}`);
			},
			[track],
		),
	};
}
