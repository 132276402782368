import { useEffect, useState } from 'react';

import { usePaypal } from 'src/app/shared/hooks/payments';
import { isLoaded } from 'src/app/shared/utils/async';

type InitializedFlow = 'checkout' | 'vault' | undefined;

export function usePaypalSDK(currency: string, flow: paypal.FlowType): InitializedFlow {
	const [initialized, setInitialized] = useState<InitializedFlow>(undefined);
	const paypalState = usePaypal();

	useEffect(() => {
		(async () => {
			if (initialized === flow || !isLoaded(paypalState)) return;
			await paypalState.paypalCheckout.loadPayPalSDK({
				currency,
				vault: flow === 'vault',
			});
			setInitialized(flow);
		})();
	}, [currency, flow, initialized, paypalState]);

	return initialized;
}
