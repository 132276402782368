import { useI18n } from '@change-corgi/core/react/i18n';

import { useConfirm } from 'src/app/shared/hooks/modals';

export function useOverwritePaymentMethodConfirmationModal(): () => Promise<boolean> {
	const { translate } = useI18n();
	return useConfirm({
		title: translate('fe.components.payments.replace_confirmation.title'),
		text: [translate('fe.components.payments.replace_confirmation.body')],
		confirmLabel: translate('fe.components.payments.replace_confirmation.confirm_replace'),
		cancelLabel: translate('fe.components.payments.replace_confirmation.cancel'),
		buttonsOrientation: 'vertical',
	});
}
