import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentType } from 'src/app/shared/utils/payments';

import { UpiForm } from '../../../../stripe/components/UpiForm';

import { useNewPaymentMethodUpi } from './hooks/useNewPaymentMethodUpi';

export type NewPaymentMethodUpiProps = {
	email: string;
	name: string;
	vpa: string;
	validationErrors: Record<string, string> | null;
	setVpa: (vpa: string) => void;
	validate: (paymentType: PaymentType) => Promise<boolean>;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	withToken: (params: WithTokenParams) => Promise<void>;
	prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
	onFieldFocusUpi: () => void;
	submitButton: React.JSX.Element;
	countryStateSelect: React.JSX.Element;
};

export function NewPaymentMethodUpi({
	submitButton,
	countryStateSelect,
	...props
}: NewPaymentMethodUpiProps): React.JSX.Element {
	const { data, actions } = useNewPaymentMethodUpi(props);
	return <UpiForm {...data} {...actions} submitButton={submitButton} countryStateSelect={countryStateSelect} />;
}
