import type { PaymentIntentResult, Stripe, StripeError } from '@stripe/stripe-js';

/* Creating a custom type to use confirmStripeUpiPayment()
 * missing currently from Stripe types.
 * see https://github.com/stripe/stripe-js/issues/462
 */
export type StripeForUpi = Stripe & {
	confirmUpiPayment: (
		clientSecret: string,
		data?: { payment_method: { upi: { vpa: string | undefined } } },
	) => Promise<PaymentIntentResult>;
};

export async function confirmStripeUpiPayment(
	stripe: Stripe,
	_paymentMethod: string | undefined,
	clientSecret: string,
): Promise<StripeError | undefined> {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/consistent-type-assertions
	const { error } = await (stripe as StripeForUpi).confirmUpiPayment(clientSecret);
	return error;
}
